import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { faWallet, faNetworkWired, faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WalletContext } from '../../../context/wallet';
import { NativeTokenPriceContext } from '../../../context/nativeTokenPrice';
import { nativeTokenName, swapAddress } from '../../../utils/commons';

import AccountModal from './components/AccountModal';

import { getAddress } from '../../../utils/commons';

import './Header.scss';

let lastScrollTop = 0;

const Header = () => {
  const [showAccountModal, setShowAccountModal] = useState(false);
  const { nativeTokenPrice } = useContext(NativeTokenPriceContext);
  const [isActive, setIsActive] = useState(false);
  const [scrollCss, setScrollCss] = useState('');
  const { walletAddress, handleConnectClick } = useContext(WalletContext);

  const nativeTokenAddress = getAddress(process.env.REACT_APP_NATIVE_TOKEN);
  // const masterChefAddress = getAddress('masterChef');

  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = window.scrollY;
      if (scrollTop === 0) {
        setScrollCss('');
      } else if (scrollTop < lastScrollTop) {
        setScrollCss('navbar-scrolled-up');
      } else {
        setScrollCss('navbar-scrolled-down');
      }
      lastScrollTop = scrollTop;
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  });

  const toggleMenu = (evt) => {
    evt.preventDefault();
    setIsActive(prevState => !prevState);
  }

  const handleDisconnectClick = () => {
    if (walletAddress !== null) {
      setShowAccountModal(true);
    }
  }

  const handleAccountModalClose = () => {
    setShowAccountModal(false);
  }

  const renderButtonConnect = (mobile = false) => {
    if (walletAddress === null) {
      return (
        <div className={ `navbar-item ${mobile ? 'is-hidden-desktop' : 'is-hidden-mobile'}` }>
          <button type="button" className="button is-primary is-outlined is-rounded" onClick={ handleConnectClick }>
            Connect
          </button>
        </div>
      );
    }

    return (
      <div className={ `navbar-item ${mobile ? 'is-hidden-desktop' : 'is-hidden-mobile'}` }>
        <button type="button" className="button is-primary is-outlined is-rounded" onClick={ handleDisconnectClick }>
          <span className="icon-text">
            <span className="icon mr-1">
              <FontAwesomeIcon icon={ faWallet } />
            </span>
            { `${walletAddress.substr(0, 2)}...${walletAddress.substr(walletAddress.length - 4)}` }
          </span>
        </button>
      </div>
    );
  }

  const blurOnClick = (evt) => {
    evt.target.blur();
    if (isActive) {
      setIsActive(prevState => !prevState);
    }
  }

  const exchangeUrl = swapAddress({
    isTokenOnly: true,
    swap: 'YodeSwap',
    token: process.env.REACT_APP_NATIVE_TOKEN,
    quoteToken: process.env.REACT_APP_NETWORK_TOKEN,
  });

  const liquidityUrl = swapAddress({
    isTokenOnly: false,
    swap: 'YodeSwap',
    token: process.env.REACT_APP_NATIVE_TOKEN,
    quoteToken: process.env.REACT_APP_NETWORK_TOKEN,
  });

  return (
    <>
      <nav className={`navbar is-fixed-top is-light ${scrollCss}`} role="navigation" aria-label="main navigation">
        <div className="container is-fluid">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item brand-title has-text-weight-bold is-size-4 has-text-primary">
              BANKSY DAO
            </Link>

            <button type="button" className={ `navbar-burger ${isActive ? 'is-active' : null}` } aria-label="menu" aria-expanded="false" data-target="mainNavbar" onClick={ toggleMenu }>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </button>
          </div>

          <div id="mainNavbar" className={`navbar-menu ${isActive ? 'is-active' : null}`}>
            <div className="navbar-start">
              <div className="navbar-item has-dropdown is-hoverable">
                { renderButtonConnect(true) }

                <div className="navbar-link is-arrowless">
                  Home
                </div>

                <div className="navbar-dropdown">
                  <Link to="/dashboard" className="navbar-item" onClick={ blurOnClick }>
                    Dashboard
                  </Link>
                  <Link to="/timeline" className="navbar-item" onClick={ blurOnClick }>
                    Timeline
                  </Link>
                  <Link to="/anti-bot" className="navbar-item" onClick={ blurOnClick }>
                    Anti-bot
                  </Link>
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless">
                  Trade
                </div>

                <div className="navbar-dropdown">
                  {nativeTokenPrice.default ? (
                    <div className="navbar-item">
                      Coming Soon
                    </div>
                  ) : (
                    <>
                      <a href={ exchangeUrl } target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
                        Exchange
                      </a>
                      <Link to={ liquidityUrl } target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
                        Liquidity
                      </Link>
                    </>
                  )}
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless">
                  Earn
                </div>

                <div className="navbar-dropdown">
                  <Link to="/farms" className="navbar-item" onClick={ blurOnClick }>
                    Farms
                    <span className="tag is-small is-warning ml-2">finished</span>
                  </Link>
                  <Link to="/pools" className="navbar-item" onClick={ blurOnClick }>
                    Pools
                    <span className="tag is-small is-warning ml-2">finished</span>
                  </Link>
                  <Link to="/walls" className="navbar-item" onClick={ blurOnClick }>
                    Walls
                    {/* <span className="tag is-small is-primary ml-2">live</span> */}
                  </Link>
                  {/* <Link to="/vaults" className="navbar-item" onClick={ blurOnClick }>
                    Vaults
                    <span className="tag is-small is-primary ml-2">live</span>
                  </Link> */}
                </div>
              </div>
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless">
                  NFT
                </div>

                <div className="navbar-dropdown">
                  <Link to="my-nfts" className="navbar-item" onClick={ blurOnClick }>
                    My NFTs
                  </Link>
                  {/* <Link to="nft-merge" className="navbar-item" onClick={ blurOnClick }>
                    Merge
                  </Link> */}
                  <Link to="nft-sale" className="navbar-item" onClick={ blurOnClick }>
                    Sale
                    {/* <span className="tag is-small is-primary ml-2">live</span> */}
                  </Link>
                  <Link to="nft-airdrop" className="navbar-item" onClick={ blurOnClick }>
                    Airdrop
                    {/* <span className="tag is-small is-primary ml-2">live</span> */}
                  </Link>
                  <Link to="nft-ranking" className="navbar-item" onClick={ blurOnClick }>
                    Ranking
                  </Link>
                </div>
              </div>
              {/* <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless">
                  Charts
                </div>
                <div className="navbar-dropdown">
                  <a href={ `https://dexscreener.com/avalanche/${nativeTokenAddress}` } target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
                    DexScreener
                  </a>
                  <a href={ `https://swap.arken.finance/tokens/avalanche/${nativeTokenAddress}` } target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
                    Arken
                  </a>
                </div>
              </div> */}
              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless">
                  Listings
                </div>
                <div className="navbar-dropdown">
                  {/* <a href="https://vfat.tools/avax/banksydao/" target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
                    Vfat tools
                  </a> */}
                  <a href="https://dappradar.com/other/defi/banksydao-finance-doge" target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
                    DappRadar
                  </a>
                  {/* <a href={ `https://avax.farmscan.io/address/${masterChefAddress}` } target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
                    FarmScan
                  </a> */}
                  {/* <a href="https://coinsniper.net/coin/29310" target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
                    CoinSniper
                  </a> */}
                </div>
              </div>
            </div>

            <div className="navbar-end">
              <a href={ (process.env.REACT_APP_NATIVE_PRICE_LINK || '#').replace(':token', nativeTokenAddress) } target="_blank" rel="noreferrer" className="navbar-item is-hidden-mobile">
                <figure className="image is-24x24">
                  <img src={ `/images/farms/${process.env.REACT_APP_NATIVE_TOKEN}.png` } alt={ nativeTokenName } />
                </figure>
                <span className="ml-1">${ nativeTokenPrice.amount.toFixed(2) }</span>
              </a>

              {/* <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless">
                  <span className="icon-text">
                    Versions
                  </span>
                </div>
                <div className="navbar-dropdown is-right">
                  <a href="https://ftm.banksy.farm" className="navbar-item has-text-link">
                    Fantom V1
                  </a>
                  <div className="navbar-item has-text-primary">
                    Fantom V2
                  </div>
                </div>
              </div> */}

              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link is-arrowless">
                  <span className="icon-text">
                    <span className="icon">
                      <FontAwesomeIcon icon={ faNetworkWired } />
                    </span>
                    <em className="is-hidden-desktop">Networks</em>
                  </span>
                </div>
                <div className="navbar-dropdown is-right">
                  <div className="navbar-item is-hidden-mobile">
                    <strong>Networks</strong>
                  </div>
                  <a href="https://avax.banksydao.finance" className="navbar-item has-text-link">
                    Avalanche
                  </a>
                  <a href="https://ftm.banksydao.finance" className="navbar-item has-text-link">
                    Fantom
                  </a>
                  <div className="navbar-item has-text-primary">
                    DogeChain
                  </div>
                  <div className="navbar-item">
                    BSC <span className="tag is-info is-small ml-1 is-rounded">coming soon</span>
                  </div>
                </div>
              </div>

              <a href="https://vote.banksydao.finance/" target="_blank" rel="noreferrer" className="navbar-item">
                <span className="icon-text">
                  <span className="icon"><FontAwesomeIcon icon={ faVoteYea } /></span>
                  <span className="ml-1">DAO</span>
                </span>
              </a>

              { renderButtonConnect() }
            </div>
          </div>
        </div>
      </nav>
      <AccountModal isModalActive={ showAccountModal } onModalClose={ handleAccountModalClose } />
    </>
  );
}

export default Header;
