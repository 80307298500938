import React, { useState, useCallback, useContext, useMemo } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import  { harvestPool } from '../../../blockchain/pools';

import { ToastContext } from '../../../context/toast';

const pad = (num) => {
  if (num < 10) {
    return `0${num}`;
  }

  return num;
}

const renderer = ({ days, hours, minutes }) => {
  return (
    <span>
      { days > 0 ? `${days} days ` : '' }{ pad(hours) }:{ pad(minutes) }
    </span>
  );
};

const PoolHarvestSection = ({ poolData }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingHarvestTx, setPendingHarvestTx] = useState(false);

  const earned = () => {
    const rawEarnedValue = new BigNumber(poolData.userPendingReward)
      .div(new BigNumber(10).pow(poolData.rewardTokenDecimals));

    const boostEarnedValue = rawEarnedValue.times(poolData.selectedNft?.boostStake || 0).div(100);

    const earnedValue = rawEarnedValue.minus(boostEarnedValue);

    return (
      <>
        <h5 className="is-size-5 has-text-weight-semibold mb-0">
          { earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6) }
        </h5>
        {/* <h5 className="is-size-5 has-text-weight-semibold mb-0">
          ~${ earnedValue.times(poolData.rewardTokenPrice).times(poolData.networkStablePrice).toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6) }
        </h5> */}
      </>
    );
  }

  const canHarvest = useMemo(() => {
    return new BigNumber(poolData.userPendingReward).gt(0) && poolData.userCanHarvest;
  }, [poolData.userPendingReward, poolData.userCanHarvest]);

  const handleHarvest = async () => {
    let tx;
    try {
      setPendingHarvestTx(true);
      tx = await harvestPool(poolData.address);
      await tx.wait();
      addToast('Harvest succeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }
    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Harvest failed', 'is-danger');
    }

    setPendingHarvestTx(false);
  }

  const renderHarvestOrCountDown = useMemo(() => {
    if (!poolData.userCanHarvest && new BigNumber(poolData.userNextHarvestUntil).gt(0)) {
      return <Countdown date={ new BigNumber(poolData.userNextHarvestUntil).times(1000).toNumber() } />
    }
    
    return <>HARVEST</>;
  }, [poolData.userNextHarvestUntil, poolData.userCanHarvest]);

  const harvestLockup = useCallback(() => {
    let lockup = new BigNumber(poolData.harvestInterval);

    if (poolData.userHasNFT) {
      const quarter = lockup.times(0.25);
      const experience = new BigNumber(poolData.selectedNft.experience);

      lockup = lockup.minus(quarter).minus(Math.min(experience.div(10).toNumber(), quarter.toNumber()));
    }

    return (
      <>
        <span className={ `has-text-danger ${poolData.userHasNFT ? 'is-line-through mr-1' : ''}` }>
          <Countdown date={ new BigNumber(poolData.harvestInterval).times(1000).plus(Date.now()).toNumber() } autoStart={ false } renderer={ renderer } />
        </span>
        {poolData.userHasNFT ? (
          <span className="has-text-success">
            <Countdown date={ lockup.times(1000).plus(Date.now()).toNumber() } autoStart={ false } renderer={ renderer } />
          </span>
        ) : null}
      </>
    );
  }, [poolData.harvestInterval, poolData.userHasNFT, poolData.selectedNft?.experience]);

  return (
    <>
      <small className="is-flex is-justify-content-space-between">
        <span><span className="has-text-success">{ poolData.rewardToken.toUpperCase() }</span> EARNED</span>
        <span>LOCKUP { harvestLockup() }</span>
      </small>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <div>
              { earned() }
            </div>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div className="is-flex is-flex-direction-column">
              <button
                disabled={ pendingHarvestTx || !canHarvest }
                className={ `button is-primary ${pendingHarvestTx ? 'is-loading' : ''}` }
                onClick={ handleHarvest }
              >
                { renderHarvestOrCountDown }
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PoolHarvestSection;
