const contractsMainNet = new Map();
const contractsTestNet = new Map();

// internal
contractsMainNet.set('masterChef', '0x0D5B8fAC50f09A6C90c6d7AE0036b1890fB92a75');
contractsMainNet.set('treasuryDAO', '0x4A78a6a8f8b3773F7A78befD106F280403EEa807');
contractsMainNet.set('treasuryUtil', '0xAEbd7A84ceD1622593ff24d667df707007F183C1');
contractsMainNet.set('multiCall', '0x114f5B67aF2d7029F2dFbe6A119DFC6B4D7A5519');

contractsMainNet.set('nftChef', '0x9C26e24ac6f0EA783fF9CA2bf81543c67cf446d2');
contractsMainNet.set('nftSale0', '0x418e4Af2E47227bA6094b5B8F77E9218c7fC53ea');
contractsMainNet.set('nftSale1', '0x3112B3166C58c3B73CB4DA3ffb036e144F14acDe');

contractsMainNet.set('nftAirdrop', '0x2f96d7015A986F128CC39C5fcAD181B856C0F681');

// single tokens
contractsMainNet.set('banksy', '0x77B5c56D36FD0E9B40D2E9Efa26D072C128b3F74');
contractsMainNet.set('wwdoge', '0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101');
contractsMainNet.set('wdoge', '0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101');
contractsMainNet.set('weth', '0xB44a9B6905aF7c801311e8F4E76932ee959c663C');
contractsMainNet.set('wbtc', '0xfa9343c3897324496a05fc75abed6bac29f8a40f');
contractsMainNet.set('usdc', '0x765277EebeCA2e31912C9946eAe1021199B39C61');
contractsMainNet.set('usdt', '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D');
contractsMainNet.set('busd', '0x332730a4F6E03D9C55829435f10360E13cfA41Ff');
// LPs
contractsMainNet.set('banksy-wwdoge', '0xaa80a817a397905cb957e22636837A6DA3Fc4779');
contractsMainNet.set('banksy-usdc', '0x70a3C4B9653067436051F6CDe37DD56b018a56E6');
contractsMainNet.set('wwdoge-usdc', '0x8DCeBE9f071562D52b5ABB17235f3bCA768C1e44');
contractsMainNet.set('weth-usdc', '0x7a3542fADA818C705E1Ce6dA508F5aa5dfC35edd');
contractsMainNet.set('busd-usdc', '0xe180b62C4CF96112e7999200EF9275F83F7E3088');
contractsMainNet.set('usdt-usdc', '0xD95b66FBD36C5754FE7C837500cccBf113fD6ac6');
contractsMainNet.set('wbtc-usdc', '0x5aC5529f6d948d565A7832B0950592845B029584');

// Fake
// contractsMainNet.set('fusdc-wwdoge', '0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101');
// contractsMainNet.set('fusdc', '0xA301eEeE073296eb27c19069F4928DfC03D95f9F');
// contractsMainNet.set('fbanksy', '0xdB1d67c070D4D4aE74E70A4EB790E3b643E9a30c');
// contractsMainNet.set('fbanksy-wwdoge', '0xa9fd3FBb9af4736ea62994f3618a23b1a9e3dbE3');
// contractsMainNet.set('fbanksy-usdt', '0x990F27a2944aB70Dca1bCF0334Cb7ED28e91081a');

export { contractsMainNet, contractsTestNet };
