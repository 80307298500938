const poolInitialState = {
  loading: false,
  tvl: 0,
  maxPoolApr: 0,
  firstLoad: true,
  pools: [
    {
      pid: 1,
      address: '0x5022742f9Eadb82471171FdF6dfB33F26E2b0571',
      stakedToken: 'banksy',
      rewardToken: 'usdc',
      name: 'BANKSY-USDC WALL',
      projectLink: 'https://www.circle.com/en/usdc',
      category: 'CORE',
      version: 'v2',
      stakedTokenDefaultPrice: 2.00,
    },
  ],
};

export default poolInitialState;
