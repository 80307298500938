import React, { useContext, useEffect, useRef } from 'react';

import { VrContext } from '../../../context/vr';
import { nftImage } from '../../../utils/nft';

const VrModal = () => {
  const { isVrModalOpen, toggleVrModal } = useContext(VrContext);
  const modalRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (width > 769) {
        if (((width - 104) * 9 / 16) > (height - 40)) {
          modalRef.current.style.width = `${(height - 40) * 16 / 9}px`;
        } else {
          modalRef.current.style.width = `${width - 104}px`;
        }
      } else {
        modalRef.current.style.width = null;
        modalRef.current.style.height = null;
      }
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  return (
    <div className={ `modal ${isVrModalOpen ? 'is-active' : ''}` }>
      <div className="modal-background" onClick={ toggleVrModal } />
      <div
        className="modal-content"
        ref={(ref) => {
          modalRef.current = ref;
        }}
      >
        <div className="box p-0 is-background-dark">
          { isVrModalOpen ? nftImage({ generation: 2, pid: 287 }) : null }
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={ toggleVrModal } />
    </div>
  );
}

export default VrModal;
