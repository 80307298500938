import { useEffect, useRef } from 'react';

export const genNameClass = (token) => {
  if (token.generation === 2) {
    return 'title is-size-5-touch is-size-4-desktop has-text-primary mb-0';
  }

  return 'title is-size-5-touch is-size-4-desktop mb-0';
}

const genName = (token) => {
  if (token.generation === 2) {
    return 'The Flower Thrower';
  }

  if (token.generation === 1) {
    return 'The DeFi Boy';
  }

  return 'Morons';
}

export const nftName = (token) => (<span>{genName(token)} #{token.pid}</span>);

const Model = ({ token }) => {
  const modelRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      modelRef.current.style.width = `${modelRef.current.parentNode.offsetWidth}px`;
      modelRef.current.style.height = `${modelRef.current.parentNode.offsetWidth * 9 / 16}px`;
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });

  return (
    <div
      className="is-flex is-justify-content-center" style={{ width: '100%' }}
    >
      <model-viewer
        id={`model-${token.pid}`}
        alt={ genName(token) }
        camera-orbit="180deg 90deg"
        touch-action="pan-y"
        src="https://nftstorage.link/ipfs/bafybeibcifrfrexamaafdnlul6waue4jfnwngboi3xjorb4nmby2efq4te"
        camera-controls
        loading="eager"
        poster="/images/poster.png"
        ref={(ref) => {
          modelRef.current = ref;
        }}
        ></model-viewer>
    </div>
  );
}

export const nftImage = (token) => {
  if (token.generation === 2) {
    return (
      <Model token={ token } />
    );
  }

  if (token.generation === 1) {
    return (
      <figure className="image is-16by9 mb-3">
        <video
          width="100%"
          height="100%"
          controls
          muted="muted"
          autoplay="autoplay"
          loop="loop"
        >
          <source src={ `/images/nfts/generation-${token.generation}.mp4` } type="video/mp4" />
        </video>
      </figure>
    );
  }

  return (
    <figure className="image is-16by9 mb-3">
      <img src={ `/images/nfts/generation-${token.generation}.png` } alt={ nftName(token) } />
    </figure>
  );
}

export const experienceScale = (experience) => experience.div(100);
