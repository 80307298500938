const farmInitialState = {
  loading: false,
  firstLoad: true,
  nativeTokenPrice: 0,
  nativeTokenPriceDefault: false,
  tvl: 0,
  tnl: 0,
  maxFarmApr: 0,
  tokenTotalSupply: 0,
  tokenTotalBurned: 0,
  tokenPerSecond: 0,
  tokenMaximumSupply: 0,
  pendingUsd: 0,
  farms: [
    // FARMS
    {
      pid: 2,
      token: 'banksy',
      quoteToken: 'wwdoge',
      isTokenOnly: false,
      name: 'BANKSY-WWDOGE LP',
      swap: 'YodeSwap',
    },
    {
      pid: 1,
      token: 'banksy',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'BANKSY-USDC LP',
      swap: 'YodeSwap',
    },
    {
      pid: 3,
      token: 'wwdoge',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'USDC-WWDOGE LP',
      swap: 'YodeSwap',
    },
    {
      pid: 4,
      token: 'usdt',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'USDC-USDT LP',
      swap: 'YodeSwap',
    },
    {
      pid: 5,
      token: 'busd',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'BUSD-USDC LP',
      swap: 'YodeSwap',
    },
    {
      pid: 6,
      token: 'weth',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'WETH-USDC LP',
      swap: 'YodeSwap',
    },
    // POOLS
    {
      pid: 0,
      token: 'banksy',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'BANKSY',
      swap: 'YodeSwap',
    },
    {
      pid: 7,
      token: 'wwdoge',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'WWDOGE',
      swap: 'YodeSwap',
    },
    {
      pid: 8,
      token: 'usdc',
      quoteToken: 'wwdoge',
      isTokenOnly: true,
      name: 'USDC',
      swap: 'YodeSwap',
    },
    {
      pid: 9,
      token: 'usdt',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'USDT',
      swap: 'YodeSwap',
    },
    {
      pid: 10,
      token: 'wbtc',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'WBTC',
      swap: 'YodeSwap',
    },
    {
      pid: 11,
      token: 'weth',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'WETH',
      swap: 'YodeSwap',
    },
  ],
}

export default farmInitialState;
