import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { faTractor, faCog } from '@fortawesome/free-solid-svg-icons';
import { faFortAwesome } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewsSection = ({ farmState, poolState, vaultState }) => {
  const [maxPoolApr, setMaxPoolApr] = useState(new BigNumber(0));

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;

      let mpa = new BigNumber(0);
      poolState.pools.forEach(pool => {
        if (new BigNumber(pool.endTime).gt(currentTime)) {
          const ts = new BigNumber(pool.apr);

          if (mpa.lt(ts)) {
            mpa = ts;
          }
        }
      });
      setMaxPoolApr(mpa);
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [poolState.pools, setMaxPoolApr]);

  const sanitizeValue = (val) => {
    const newValue = new BigNumber(val).times(100);
    if (newValue.isNaN()) {
      return (<FontAwesomeIcon icon={ faCog } spin />);
    }

    return newValue.gt(new BigNumber(10).pow(10)) ? 'INSANE' : newValue.toFormat(2);
  }

  return (
    <div className="columns">
      <div className="column">
        <div className="message is-info">
          <div className="message-body">
            <div className="icon-text is-align-items-center">
              <span className="icon is-large">
                <FontAwesomeIcon icon={ faTractor } size="2x" />
              </span>
              <span className="is-size-4">in Farms</span>
            </div>
            <h5 className="subtitle">Earn up to</h5>
            <h3 className="title is-4">{ sanitizeValue(farmState.maxFarmApr) }%</h3>
            <h4 className="subtitle is-6">APR</h4>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="message is-success">
          <div className="message-body">
            <div className="icon-text is-align-items-center">
              <span className="icon is-large">
                <FontAwesomeIcon icon={ faFortAwesome } size="2x" />
              </span>
              <span className="is-size-4">in Walls</span>
            </div>
            <h5 className="subtitle">Earn up to</h5>
            <h3 className="title is-4">{ sanitizeValue(maxPoolApr) }%</h3>
            <h4 className="subtitle is-6">APR</h4>
          </div>
        </div>
      </div>
      {/* <div className="column">
        <div className="message is-warning">
          <div className="message-body">
            <div className="icon-text is-align-items-center">
              <span className="icon is-large">
                <FontAwesomeIcon icon={ faSync } size="2x" />
              </span>
              <span className="is-size-4">in Vaults</span>
            </div>
            <h5 className="subtitle">AUTO-COMPOUNDING</h5>
            <h4 className="title is-4">{ sanitizeValue(vaultState.maxVaultApy) }%</h4>
            <h3 className="subtitle is-6">APY</h3>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default NewsSection;
