import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import { faCheck, faCog, faStopwatch, faTractor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeNav from '../home/components/HomeNav';
import NftCard from '../nft/components/NftCard';

import { fetchTimeline } from '../../blockchain/timeline';

const TimelinePage = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const [provideLiquidityStarted, setProvideLiquidityStarted] = useState(false);
  const [masterChefStarted, setMasterChefStarted] = useState(false);
  const [nftSale0Started, setNftSale0Started] = useState(false);
  const [nftSale1Started, setNftSale1Started] = useState(false);
  const [nftAirdropStarted, setNftAirdropStarted] = useState(false);
  const [wall0Started, setWall0Started] = useState(false);

  const [timelineState, setTimelineState] = useState({
    firstLoad: true,

    masterChefStartTime: 0,

    nftSale0StartTime: 0,
    nftSale0BalanceOf: 0,
    nftSale1StartTime: 0,
    nftSale1BalanceOf: 0,
    nftSaleCurrent: 0,

    nftAirdropStartTime: 0,
    nftAirdropBalanceOf: 0,
  });

  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncTimeline = async () => {
      setTimelineState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchTimeline();
      setTimelineState(prevState => ({ ...prevState, ...rs, loading: false }));
    }

    const intervalId = setInterval(syncTimeline, 5000);

    syncTimeline();

    return () => clearInterval(intervalId);
  }, [setTimelineState]);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;
      const masterChefStartTime = new BigNumber(timelineState.masterChefStartTime);
      const nftSale0StartTime = new BigNumber(timelineState.nftSale0StartTime);
      const nftSale1StartTime = new BigNumber(timelineState.nftSale1StartTime);
      const wall0StartTime =  new BigNumber(process.env.REACT_APP_WALL_0_START);
      const nftAirdropStartTime = new BigNumber(timelineState.nftAirdropStartTime);
      const launchApp = new BigNumber(process.env.REACT_APP_LAUNCH_AT);

      setMasterChefStarted(prevState => prevState || (masterChefStartTime.gt(0) && masterChefStartTime.lt(currentTime)));
      setNftSale0Started(prevState => prevState || (nftSale0StartTime.gt(0) && nftSale0StartTime.lt(currentTime)));
      setNftSale1Started(prevState => prevState || (nftSale1StartTime.gt(0) && nftSale1StartTime.lt(currentTime)));
      setWall0Started(prevState => prevState || (wall0StartTime.gt(0) && wall0StartTime.lt(currentTime)));
      setNftAirdropStarted(prevState => prevState || (nftAirdropStartTime.gt(0) && nftAirdropStartTime.lt(currentTime)));
      setProvideLiquidityStarted(prevState => prevState || (launchApp.gt(0) && launchApp.lt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [timelineState, setMasterChefStarted, setNftSale0Started, setWall0Started, setNftAirdropStarted]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const isLoading = () => timelineState.firstLoad && timelineState.loading;

  return (
    <>
      <div className="parallax" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }} />
      <HomeNav />
      <header className="hero is-relative">
        <div className="parallax-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }} />
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">Banksy DAO Timeline</p>
              <p className="subtitle">
                Check our Timeline in <span className="has-text-primary">Realtime</span>. Our contracts executes as <span className="has-text-primary">DAO</span>.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section has-background-white">
        <div className="container">
          <div className={ `timeline ${width > 1023 ? 'is-centered' : null}` }>
            <header className="timeline-header">
              <span className="tag is-medium is-rounded is-primary">
                { isLoading() ? <FontAwesomeIcon icon={ faCog } spin /> : <>Start</> }
              </span>
            </header>

            <div className={ `timeline-item ${isLoading() || !nftSale0Started ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !nftSale0Started ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !nftSale0Started ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Sep 27th, 15:00 UTC
                </p>
                <p>3D SMART NFT SALE - EARLY ACCESS 1 (50% off)</p>
                <div>
                  <NftCard nftData={{
                    pid: 1,
                    experience: 287,
                    generation: 2,
                    power: 27,
                    boostStake: 12.18,
                  }} hideDetails />
                  <Link to="/nft-sale" className="button is-primary is-fullwidth">
                    Buy
                  </Link>
                </div>
                <p className="has-text-info mt-3">* { isLoading() ? (<FontAwesomeIcon icon={ faCog } spin />) : new BigNumber(timelineState.nftSale0BalanceOf).toFormat() } 3D SMART NFTs remaining</p>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || !provideLiquidityStarted ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !provideLiquidityStarted ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || !provideLiquidityStarted ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Sep 30th, 15:00 UTC
                </p>
                <p>
                  TOKEN LAUNCH<br />
                  <small>Initial liquidity Provided</small>
                </p>
                <div className="p-3">
                  <figure className="image is-128x128 mb-3">
                    <img src="/images/farms/banksy.png" alt="BANKSY" />
                  </figure>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${ isLoading() || !wall0Started ? 'is-primary' : 'is-danger' }` }>
              <div className={ `timeline-marker is-icon is-medium ${ isLoading() || !wall0Started ? 'is-primary' : 'is-danger' }` }>
                <FontAwesomeIcon icon={ isLoading() || !wall0Started ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Sep 30th, 19:00 UTC
                </p>
                <p>
                  BANKSY WALL<br />
                  <small>Stake Banksy Earn USDC</small>
                </p>
                <div>
                  <div className="p-3">
                    <figure className="image is-128x128 mb-3">
                      <img src="/images/farms/banksy.png" alt="BANKSY" />
                    </figure>
                    <Link to="/walls" disabled={ isLoading() } className="button is-primary">
                      Stake on Wall
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || !nftAirdropStarted ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !nftAirdropStarted ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !nftAirdropStarted ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Oct 3rd, 12:00 UTC
                </p>
                <p>ARTWORK 3D SMART NFT AIRDROP</p>
                <div>
                  <NftCard nftData={{
                    pid: 1,
                    experience: 287,
                    generation: 2,
                    power: 27,
                    boostStake: 12.18,
                  }} hideDetails />
                  <Link to="/nft-airdrop" className="button is-primary is-fullwidth mb-3" disabled={ !nftAirdropStarted }>
                    Open 3D SMART NFT Airdrop
                  </Link>
                  <div className="has-text-info">
                    * Only 10 3D SMART NFT for AIRDROP.<br />
                    * { isLoading() ? (<FontAwesomeIcon icon={ faCog } spin />) : new BigNumber(timelineState.nftAirdropBalanceOf).toFormat(0) } 3D SMART NFTs remaining.
                  </div>
                </div>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || !masterChefStarted ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !masterChefStarted ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !masterChefStarted ? faTractor : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Oct 3rd, 15:00 UTC
                </p>
                <p className="mr-4">
                  <Link to="/farms">
                    Start Farming
                  </Link>
                </p>
                <figure className="image is-128x128 mb-3">
                  <img src="/images/farms/banksy.png" alt="BANKSY" />
                </figure>
              </div>
            </div>

            <div className={ `timeline-item ${isLoading() || !nftSale1Started ? 'is-primary' : 'is-danger'}` }>
              <div className={ `timeline-marker is-icon is-medium ${isLoading() || !nftSale1Started ? 'is-primary' : 'is-danger'}` }>
                <FontAwesomeIcon icon={ isLoading() || !nftSale1Started ? faStopwatch : faCheck } />
              </div>
              <div className="timeline-content">
                <p className="heading is-flex is-align-items-center">
                  Oct 5th, 15:00 UTC
                </p>
                <p>3D SMART NFT SALE - EARLY ACCESS 2 (25% off)</p>
                <div>
                  <NftCard nftData={{
                    pid: 1,
                    experience: 287,
                    generation: 2,
                    power: 27,
                    boostStake: 12.18,
                  }} hideDetails />
                  <Link to="/nft-sale" className="button is-primary is-fullwidth">
                    Buy
                  </Link>
                </div>
                <p className="has-text-info mt-3">* { isLoading() ? (<FontAwesomeIcon icon={ faCog } spin />) : new BigNumber(timelineState.nftSale1BalanceOf).toFormat() } 3D SMART NFTs remaining</p>
              </div>
            </div>

            <header className="timeline-header">
              <span className="tag is-medium is-rounded is-primary">To the Moon!</span>
            </header>
          </div>
        </div>
      </main>
      <div className="parallax-bottom" />
    </>
  );
}

export default TimelinePage;
