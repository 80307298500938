import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import CountUpNumber from '../../../layout/components/CountUpNumber';
import { stableTokenName } from '../../../utils/commons';

import { fetchTreasury } from '../../../blockchain/treasury';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

const TreasurySection = ({ farmState }) => {
  const [treasuryState, setTreasuryState] = useState({
    tvl: 0,
    tor: 0,
    loading: true,
    firstLoad: true,
  });

  useEffect(() => {
    const syncTreasury = async () => {
      setTreasuryState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchTreasury();
      setTreasuryState(prevState => ({ ...prevState, ...rs, loading: false }));
    }

    const intervalId = setInterval(syncTreasury, 20000);

    syncTreasury();

    return () => clearInterval(intervalId);
  }, [setTreasuryState]);

  const totalUsdCollected = new BigNumber(farmState.totalUsdCollected).div(new BigNumber(10).pow(6));

  const renderTor = () => {
    if (treasuryState.firstLoad) {
      return (
        <FontAwesomeIcon icon={ faCog } spin />
      );
    }

    const tvl = new BigNumber(treasuryState.tvl).plus(treasuryState.tor);

    return (
      <CountUpNumber
        value={ tvl.toNumber() }
        decimals={ tvl.gt(1) ? 2 : 6 }
      />
    );
  }

  return (
    <div className="box">
      <h3 className="subtitle has-text-centered-mobile has-text-left-dekstop">Treasury DAO</h3>
      <div className="columns">
        <div className="column">
          <p className="subtitle">Total Owner Reward (TOR)</p>
          <p className="title is-2"><small className="is-size-3">$</small>{ renderTor() }</p>
        </div>
        <div className="column">
          <p className="subtitle">{ stableTokenName } distributed</p>
          <p className="title is-2"><small className="is-size-3">$</small><CountUpNumber value={ farmState.firstLoad ? 0 : totalUsdCollected.toNumber() } decimals={ totalUsdCollected.gt(1) ? 0 : 2 } /></p>
        </div>
      </div>
    </div>
  );
}

export default TreasurySection;
