import React, { useState, useContext, useCallback, useEffect } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faUserSecret } from '@fortawesome/free-solid-svg-icons';

import { WalletContext } from '../../../context/wallet';
import { ToastContext } from '../../../context/toast';

import { approvePool } from '../../../blockchain/pools';

const pad = (num) => {
  if (num < 10) {
    return `0${num}`;
  }

  return num;
}

const renderer = ({ days, hours, minutes }) => {
  return (
    <span>
      { days > 0 ? `${days} days ` : '' }{ pad(hours) }:{ pad(minutes) }
    </span>
  );
}

const PoolStakeSection = ({ poolData, handleWithdrawModalOpen, handleDepositModalOpen, ended }) => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);

  const [started, setStarted] = useState(false);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;
      const ss = new BigNumber(process.env.REACT_APP_LAUNCH_AT);

      setStarted(prevState => prevState || (ss.gt(0) && ss.lt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [setStarted]);

  const poolStakedBalance = () => {
    const userAmount = new BigNumber(poolData.userAmount);

    return (
      <>
        <h5 className="is-size-5 has-text-weight-semibold mb-0">
          { userAmount.div(new BigNumber(10).pow(poolData.stakedTokenDecimals)).toFormat(2) }
        </h5>
        <h5 className="is-size-5 has-text-weight-semibold mb-0">
          ~${ userAmount.times(poolData.stakedTokenPrice).times(poolData.networkStablePrice).div(new BigNumber(10).pow(poolData.stakedTokenDecimals)).toFormat(2) }
        </h5>
      </>
    );
  }

  const hasStakedBalance = () => new BigNumber(poolData.userAmount).gt(0);

  const handleApprove = async () => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approvePool(poolData.address, poolData.stakedToken);
      await tx.wait();
      addToast('Wall Staked Token Approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Wall Staked Token Approve failed', 'is-danger');
    }
    setPendingTx(false);
  }

  const renderMinusOrCountdown = useCallback(() => {
    if (!poolData.userCanWithdraw) {
      if (poolData.withdrawWhenFinished && !ended) {
        return (<span><Countdown date={ new BigNumber(poolData.endTime).times(1000).toNumber() } /></span>);
      } else if (!poolData.userCanWithdraw) {
        return (<span><Countdown date={ new BigNumber(poolData.userNextWithdrawUntil).times(1000).toNumber() } /></span>);
      }
    }

    return (
      <span className="icon">
        <FontAwesomeIcon icon={ faMinus } />
      </span>
    );
  }, [poolData.userCanWithdraw, poolData.withdrawWhenFinished, poolData.endTime, poolData.userNextWithdrawUntil, ended]);

  const renderUnlockApproveOrStake = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    if (new BigNumber(poolData.userAllowance).eq(0)) {
      return (
        <button
          disabled={ pendingTx || !started || ended }
          className={ `button is-primary ${pendingTx ? 'is-loading' : ''}` }
          onClick={ handleApprove }
        >
          {!started ? (
            <span className="icon">
              <FontAwesomeIcon icon={faUserSecret} />
            </span>
          ) : null}
          <span>APPROVE</span>
        </button>
      );
    }

    if (hasStakedBalance()) {
      return (
        <div className="buttons">
          <button disabled={ !poolData.userCanWithdraw } className="button is-primary is-outlined" onClick={ () => handleWithdrawModalOpen(poolData) }>
            { renderMinusOrCountdown() }
          </button>
          <button disabled={ ended } className="button is-primary is-outlined" onClick={ () => handleDepositModalOpen(poolData) }>
            <span className="icon">
              <FontAwesomeIcon icon={ faPlus } />
            </span>
          </button>
        </div>
      );
    }

    return (
      <button disabled={ ended } className="button is-primary" onClick={ () => handleDepositModalOpen(poolData) }>
        STAKE
      </button>
    );
  }

  const withdrawLockup = () => {
    if (poolData.withdrawWhenFinished) {
      return (<>LOCKUP <span className="has-text-danger">UNTIL END</span></>);
    }

    const withdrawInterval = new BigNumber(poolData.withdrawInterval);

    if (withdrawInterval.eq(0)) {
      return (<span className="has-text-success">NO WITHDRAW LOCKUP</span>);
    }

    let lockup = new BigNumber(poolData.withdrawInterval);
    if (poolData.userHasNFT) {
      const quarter = lockup.times(0.25);
      const experience = new BigNumber(poolData.selectedNft.experience);

      lockup = lockup.minus(quarter).minus(Math.min(experience.div(10).toNumber(), quarter.toNumber()));
    }

    return (
      <>
        LOCKUP 
        <span className={ `has-text-danger ml-1 ${poolData.userHasNFT ? 'is-line-through mr-1' : ''}` }>
          <Countdown date={ withdrawInterval.times(1000).plus(Date.now()).toNumber() } autoStart={ false } renderer={ renderer } />
        </span>
        {poolData.userHasNFT ? (
          <span className="has-text-success">
            <Countdown date={ lockup.times(1000).plus(Date.now()).toNumber() } autoStart={ false } renderer={ renderer } />
          </span>
        ) : null}
      </>
    );
  }

  return (
    <>
      <div className="level is-mobile mb-0">
        <div className="level-left">
          <div className="level-item">
          <small>STAKED <span className="has-text-success is-block is-inline-widescreen">{ poolData.stakedToken.toUpperCase() }</span></small>
          </div>
        </div>
        <div className="level-right">
          <small>{ withdrawLockup() }</small>
        </div>
      </div>
      <div className="columns is-multiline is-align-items-center is-widescreen">
        <div className="column">
          <div>
            { poolStakedBalance() }
          </div>
        </div>
      <div className="column is-narrow">
        { renderUnlockApproveOrStake() }
      </div>
      </div>
    </>
  );
}

export default PoolStakeSection;
